import { Card } from 'antd';
import { extend } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import StockTable from './component/StockTable';
import { useTitle } from 'ahooks';
extend(isBetween);

export default function Stock() {
	useTitle('库存');

	return (
		<Card>
			<StockTable havingOps={true} />
		</Card>
	);
}
